import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import Skeleton from "react-loading-skeleton";
import ImageFullLoad from "components/ImageFullLoad";

function HomePageSlider({ data, setChild, setParent }) {
	// const [showSlider, setShowSlider] = useState(true);
	// useEffect(() => {
	//     if (!data) return;
	//     var img = new Image();
	//     img.src = data[0]?.image;
	//     img.onload = function () {
	//         setShowSlider(true)
	//     }
	// }, [data])

	return data ? (
		<>
			<Swiper autoplay loop={true} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper">
				{data?.map((item, index) => {
					let linkId = false;
					let isDetails = false;

					if (!item.isClickDisabled && item.redirect) {
						linkId = item?.redirect;
					}

					if (item.slug === "details") {
						linkId = true;
						isDetails = true;
					}

					return (
						<SwiperSlide key={`slider-${index}`}>
							<Link
								key={item.id}
								to={
									isDetails
										? {
												pathname: `/details`,
										  }
										: linkId
										? {
												pathname: `/product/banner/${linkId}`,

												// item?.children > 0
												//     ? `/child/${item.id}`
												//     : `/product/${item.id}`,
										  }
										: "/"
								}
								className="col-12 col-sm-6 col-md-3"
								onClick={(e) => {
									if (!linkId) e?.preventDefault?.();
									return;
									// if (!item?.children) {
									//     setChild(false);
									// }
									// setParent(item);
									// console.log("datass", data);
								}}>
								<ImageFullLoad
									// className={styles.image}
									// style={{ width: "100%", height: "50%" }}
									src={item.image}
									alt={item.name}
									parentProps={{ className: styles.imageBox }}
									className={styles.image}
								/>
							</Link>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	) : (
		<div className={styles.box}>
			<div className={styles.skeleton}>
				<Skeleton style={{ height: "100%" }} />
			</div>
		</div>
	);
}
export default HomePageSlider;
