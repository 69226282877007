import { useUtmSource } from "_context/utmSource/uuidProvider";
import FullScreenPopup from "components/languagePopup";
import { getHotel } from "api/api";
import { useQuery } from "react-query";
import { useUuid } from "_context/uuid/uuidProvider";
import ErrorTime from "components/ErrorTime";
import { useTranslation } from "react-i18next";
import SupsisEmbedCode from "components/SupsisEmbedCode";
// import { useEffect } from "react";

const Middleware = ({ children }) => {
  const { t, i18n } = useTranslation();

  const { data: UtmData, setLanguage } = useUtmSource();
  // const queryClient = useQueryClient();
  console.log({ UtmData });

  const { data: uuid } = useUuid();
  const { data, isError, error, isLoading } = useQuery(
    "hotel",
    () => getHotel(uuid),
    {
      enabled: !!uuid,
      retry: false,
      onSuccess: (data) => {
        console.log({ hotel: data });
        const isCurrentRegion = [
          // // --------------
          // "65cb66f62baa24eac088306a",
          // // --------------
          "67b2e1491378608dbe022a13",
          "67b2e19c1378608dbe022a86",
          "67b2e1731378608dbe022a74",
          "678e9d44bdea89f07df94742",
          "65ae669c2ff480bb03ce33cc",
          "65f02db60d39fc8bd4ae7f37",
          "67cadfeed50b32340ea15975",
          "67cae023d50b32340ea159a8",
        ].includes(data?.region?._id || data?.region?.id);
        // console.log({ isCurrentRegion });

        if (!UtmData?.language && isCurrentRegion) {
          i18n.changeLanguage("tr");
          setLanguage("tr");
        } else if (!UtmData?.language && data?.defaultLanguage) {
          i18n.changeLanguage(data?.defaultLanguage);
          setLanguage(data?.defaultLanguage);
        }
        // defaultLanguage
      },
    }
  );
  // console.log("* * * Middleware * * *", !UtmData?.language, { data, isError, error, isLoading, UtmData });

  // useEffect(() => {
  // 	if (!UtmData?.language) return;
  // 	// queryClient.invalidateQueries("parentCategories");
  // }, [UtmData?.language]);
  if (isError) {
    return <ErrorTime message={error?.response?.data?.message} />;
  }
  // if (!UtmData?.language)
  // 	return (
  // 		<FullScreenPopup
  // 			isOpen={!UtmData?.language}
  // 			onChange={(e) => {
  // 				setLanguage(e);
  // 				queryClient.invalidateQueries("parentCategories");
  // 			}}
  // 		/>
  // 	);

  return (
    <>
      {/* <FullScreenPopup
				lang={UtmData?.language}
				isOpen={!UtmData?.language}
				onChange={(e) => {
					setLanguage(e);
					//
				}}
			/> */}
      <SupsisEmbedCode />
      {children}
    </>
  );
};

export default Middleware;

// import { useUtmSource } from "_context/utmSource/uuidProvider";
// import FullScreenPopup from "components/languagePopup";

// const Middleware = ({ children }) => {
//     const {
//         data: UtmData,
//         setLanguage
//     } = useUtmSource();

//     // useEffect(() => {
//     //     let pop_status = localStorage.getItem("pop_status");
//     //     // let pop_order = localStorage.getItem("pop_order");
//     //     if (!pop_status) {
//     //         setVisible(true);
//     //     }
//     // }, [visible]);

//     if (!UtmData?.language) return (
//         <FullScreenPopup
//             isOpen={!UtmData?.language}
//             onChange={(e) => setLanguage(e)}
//         />
//     )

//     return children
// }

// export default Middleware;
